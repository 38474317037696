import { useCallback, useEffect } from 'react';

import { getLocalStorageItem, setLocalStorageItem } from '@sbiz/util-browser';
import { getDateInPast } from '@sbiz/util-dates';

import { STORAGE_KEYS } from '../..';
import { getAuthUser, SITE_DATA_CLEARANCE_INTERVAL_MINUTES } from '../../session';
import { useAuthApi } from './useAuthApi';
import { useLogout } from './useLogout';

export function useSiteDataClearance() {
  const { siteDataClearance } = useAuthApi();
  const logout = useLogout();

  const handleSiteDataClearance = useCallback(
    async (siteDataClearedAt?: Date) => {
      const { data } = await siteDataClearance({ siteDataClearedAt });
      if (data?.isRequired === true) {
        logout();
      } else {
        setLocalStorageItem(STORAGE_KEYS.siteDataClearance, new Date());
      }
    },
    [logout, siteDataClearance],
  );

  useEffect(() => {
    const authUser = getAuthUser();

    if (!authUser) {
      setLocalStorageItem(STORAGE_KEYS.siteDataClearance, new Date());
      return;
    }

    const clearedAt = getLocalStorageItem<string>(STORAGE_KEYS.siteDataClearance);

    if (clearedAt) {
      const clearDate = new Date(clearedAt);
      const limitDate = getDateInPast(SITE_DATA_CLEARANCE_INTERVAL_MINUTES, 'minutes');

      if (clearDate < limitDate) {
        handleSiteDataClearance(clearDate);
      }
    } else {
      handleSiteDataClearance();
    }
  }, [handleSiteDataClearance]);
}
