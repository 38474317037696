import i18n from 'i18next';
import LanguageDetector, { DetectorOptions } from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

import { Lang } from '@sbiz/common';

import { COOKIE_KEYS, LANG_COOKIE_OPTIONS } from '../../constants';
import { CONFIG } from '../constants';

export function initI18n(lng?: Lang) {
  i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(resourcesToBackend((lang: Lang) => import(`../locales/${lang}/common.json`)))
    .init<DetectorOptions>({
      ...CONFIG,
      detection: {
        caches: ['cookie'],
        cookieOptions: LANG_COOKIE_OPTIONS,
        lookupCookie: COOKIE_KEYS.lang,
        order: ['cookie'],
      },
      lng,
    });
}
