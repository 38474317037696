import { RumGlobal } from '@datadog/browser-rum';

import { getWindow } from '@sbiz/util-browser';

export function getDatadogInstance() {
  const window = getWindow('Datadog');

  if (window && 'DD_RUM' in window) {
    return window?.DD_RUM as RumGlobal;
  }
}

export function isDatadogInitialized() {
  const datadogRum = getDatadogInstance();
  const context = datadogRum?.getInternalContext();
  return Boolean(context?.application_id && context?.session_id);
}
