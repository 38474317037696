import axios from 'axios';
import { useCallback, useEffect, useState } from 'react';

import { Console, getStorageItem, setStorageItem } from '@sbiz/util-browser';

import { STORAGE_KEYS } from '../common';
import { useApiConfig } from '../common/api/hooks/useApiConfig';
import { useClearCache } from '../common/api/hooks/useClearCache';

export function useFeatureApiUrl() {
  const [isLoading, setIsLoading] = useState(true);

  const { featureApiUrl, getBaseURL } = useApiConfig();
  const clearCache = useClearCache();

  const setFeatureApiUrl = useCallback(async () => {
    const apiUrlOverride = getStorageItem<string>(STORAGE_KEYS.apiUrl);

    if (!apiUrlOverride && featureApiUrl && featureApiUrl !== getBaseURL()) {
      try {
        await axios.get(`${featureApiUrl}/health/liveness`);
        setStorageItem(STORAGE_KEYS.apiUrl, featureApiUrl);
        clearCache();
      } catch (error) {
        Console.error(error);
      }
    }

    setIsLoading(false);
  }, [clearCache, featureApiUrl, getBaseURL]);

  useEffect(() => {
    setFeatureApiUrl();
  }, [setFeatureApiUrl]);

  return { isLoading };
}
