import { datadogRum } from '@datadog/browser-rum';
import { useEffect, useRef, useState } from 'react';

import { isDatadogInitialized } from '../common/datadog';
import { useConfigContext } from '../contexts/ConfigContext';

export function useDatadog() {
  const [isLoading, setIsLoading] = useState(true);

  const isInitialized = useRef(false);

  const {
    app: { environment: env, name: service, version },
    datadog: { active, ...config },
  } = useConfigContext();

  useEffect(() => {
    if (!isInitialized.current) {
      isInitialized.current = true;

      if (active && !isDatadogInitialized()) {
        datadogRum.init({
          ...config,
          allowedTracingUrls: [/https:\/\/(?!.*(cdn|sso)).*\.smood\.(ch|dev|business)/],
          defaultPrivacyLevel: 'mask-user-input',
          env,
          service,
          version,
        });
      }

      setIsLoading(false);
    }
  }, [active, config, env, service, version]);

  return { isLoading };
}
